import http from "../api/httpRequest.js";
import { TERMINAL_TYPE } from "../api/enums.js";

export default {
  state: {
    friends: [],
    activeFriend: null,
    timer: null,
    instanceId: "",
  },
  mutations: {
    setFriends(state, friends) {
      state.friends = friends;
    },
    setInstanceId(state, id) {
      state.instanceId = id;
    },
    updateFriend(state, friend) {
      state.friends.forEach((f, index) => {
        if (f.id == friend.id) {
          // 拷贝属性
          let online = state.friends[index].online;
          Object.assign(state.friends[index], friend);
          state.friends[index].online = online;
        }
      });
    },
    activeFriend(state, idx) {
      state.activeFriend = state.friends[idx];
    },
    removeFriend(state, idx) {
      if (state.friends[idx] == state.activeFriend) {
        state.activeFriend = null;
      }
      state.friends.splice(idx, 1);
    },
    addFriend(state, friend) {
      state.friends.push(friend);
    },
    refreshOnlineStatus(state) {
      let userIds = [];
      if (state.friends.length == 0) {
        return;
      }
      state.friends.forEach((f) => {
        userIds.push(f.id);
      });
      http({
        url: "/user/terminal/online",
        method: "get",
        params: { userIds: userIds.join(",") },
      }).then((onlineTerminals) => {
        this.commit("setOnlineStatus", onlineTerminals);
      });

      // 30s后重新拉取
      clearTimeout(state.timer);
      state.timer = setTimeout(() => {
        this.commit("refreshOnlineStatus");
      }, 30000);
    },
    setOnlineStatus(state, onlineTerminals) {
      state.friends.forEach((f) => {
        let userTerminal = onlineTerminals.find((o) => f.id == o.userId);
        if (userTerminal) {
          f.online = true;
          f.onlineTerminals = userTerminal.terminals;
          f.onlineWeb = userTerminal.terminals.indexOf(TERMINAL_TYPE.WEB) >= 0;
          f.onlineApp = userTerminal.terminals.indexOf(TERMINAL_TYPE.APP) >= 0;
        } else {
          f.online = false;
          f.onlineTerminals = [];
          f.onlineWeb = false;
          f.onlineApp = false;
        }
      });
      // 在线的在前面
      state.friends.sort((f1, f2) => {
        if (f1.online && !f2.online) {
          return -1;
        }
        if (f2.online && !f1.online) {
          return 1;
        }
        return 0;
      });
    },
    clear(state) {
      clearTimeout(state.timer);
      state.friends = [];
      state.timer = null;
      state.activeFriend = [];
    },
  },
  actions: {
    loadFriend(context, state) {
   
      const instanceId = localStorage.getItem("instanceId");
      console.log("loadFriend",instanceId);
      return new Promise((resolve, reject) => {
        http({
          url: "/friend/list",
          method: "GET",
          params: { instanceId: instanceId },
        })
          .then((friends) => {
            console.log(friends[0].id, "friends[0].id");
            localStorage.setItem("targetId", friends[0].id);

            context.commit("setFriends", friends);
            context.commit("refreshOnlineStatus");
            resolve();
          })
          .catch((err) => {
            console.log(err)
            reject();
          });
      });
    },
  },
};
